*{
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}
.sidebar{
    position: fixed;
    left: -250px;
    width: 250px;
    height: 105%;
    background: #042331;
    transition: all .5s ease;
}
.sidebar h1{
   font-size: 22px;
   color: white;
   text-align: center;
   line-height: 70px;
   background: #063146;
   user-select: none; 
}
.sidebar ul a{
    display: block;
    height: 100%;
    width: 100%;
    line-height: 65px;
    font-size: 20px;
    color: white;
    padding-left: 40px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, .1);
    border-bottom: 1px solid black;
    transition: .4s;
}
ul li:hover a{
   padding-left: 50px;
}
.sidebar ul a i{
    margin-right: 16px;
}
#check{
    display: none;
}
label #btn,label #cancel{
    position: absolute;
    cursor: pointer;
    background: #042331;
    border-radius: 3px;
}
label #btn{
    left: 40px;
    top: 25px;
    font-size: 35px;
    color: white;
    padding: 6px 12px;
    transition: all .5s;
}
label #cancel{
    z-index: 1111;
    left: -195px;
    top: 17px;
    font-size: 30px;
    color: #0a5275;
    padding: 4px 9px;
    transition: all .5s ease;
}
#check:checked ~ .sidebar{
    left: 0;
}
#check:checked ~ label #btn{
    left: 250px;
    opacity: 0;
    pointer-events: none;
}
#check:checked ~ label #cancel{
    left: 215px;
 
}